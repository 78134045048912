import menu, { closeMenu, openMenu, openSubMenu } from "./components/menu";

menu.button.open.forEach((open)=>{
    open.addEventListener("click", openMenu);
});

menu.button.close.forEach((close)=>{
    close.addEventListener("click", closeMenu);
});
menu.overlay.addEventListener("click", closeMenu);
menu.dropDowns.forEach((dropDown) => {
    dropDown.addEventListener("click", openSubMenu);
});

